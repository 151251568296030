import React from "react"

import NewsPage from "../../components/News/NewsPage"
import { news } from "../../components/News/data"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const NewsArticlePage = props => {
  const { location } = props
  const data = news[44]

  return (
    <Layout path={location.pathname}>
      {
        // eslint-disable-next-line
        <SEO
          title={data.title}
          description={`${data.paragraphs[0].slice(0, 150)}...`}
        />
      }
      <div className="overflow-hidden bg-gray-100 pt-20 lg:pt-16">
        <NewsPage data={data} />
      </div>
    </Layout>
  )
}

export default NewsArticlePage
